import React from "react"
import { graphql } from "gatsby"
import EventGroups, { ALL } from "../../components/EventGroups/eventGroups"
import Footer from "../../components/Footer/footer"
import Info from "../../components/Info/info"
import "./team-template.scss"
import BaseLayout from "../../components/Layout/layout"

const TeamTemplate = ({ data, pageContext }) => {
  const team = data.team
  const homeTeamEvents = data.homeEvents ? data.homeEvents.edges : []
  const awayTeamEvents = data.awayEvents ? data.awayEvents.edges : []
  const allEvents = homeTeamEvents.concat(awayTeamEvents)
  const logoFolder =
    team.sportCode && team.sportCode.replace(/\s+/g, "").toLowerCase()
  const teamLogo = team.logoUrl
    ? team.logoUrl
    : team.betradarId && logoFolder
    ? `/logos/team/${logoFolder}/${team.betradarId}.png`
    : "/logos/team/fallback.png"

  //console.log("team page context", pageContext)
  return (
    <BaseLayout>
      <div className="team-header-container">
        <div className="team-header">
          <img
            src={teamLogo}
            onError={e =>
              e.target.setAttribute("src", "/logos/team/fallback.png")
            }
            className="team-logo"
            alt={team.name}
          />
          <h1 className="team-name">{team.name}</h1>
        </div>
      </div>
      <Info
        header={`Alla ${team.name}s matcher på TV & Stream`}
        description={`Här hittar du alla matcher med ${team.name} som sänds antingen på TV
          eller via en streamingtjänst på nätet. Klicka på loggorna för mer information om
          respektive sändning och hur du får tag på matchen. Du kan även klicka på matcherna
          för att få information om matchodds, eventuella speltips och förhandsanalyser.`}
      />
      <div className="team-content-container">
        <EventGroups
          key={`team-events-${team.name}`}
          eventEdges={allEvents}
          numberOfDays={ALL}
        />
      </div>
      <Footer links={pageContext.allLinks} />
    </BaseLayout>
  )
}

export default TeamTemplate

export const pageQuery = graphql`
  query teamQuery($id: String!) {
    team: contentfulTeam(contentful_id: { eq: $id }) {
      name
      betradarId
      logoUrl
      sportCode
    }
    homeEvents: allContentfulEvent(
      filter: { homeTeamId: { eq: $id }, node_locale: { eq: "sv-SE" } }
    ) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          everysportId
          bookmakerUrl
#          bookmaker {
#            name
#            externalUrl
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          league {
            name
            uri
          }
          sport {
            code
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          channels {
            name
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
    awayEvents: allContentfulEvent(
      filter: { awayTeamId: { eq: $id }, node_locale: { eq: "sv-SE" } }
    ) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          everysportId
          bookmakerUrl
#          bookmaker {
#            name
#            externalUrl
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          league {
            name
            uri
          }
          sport {
            code
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          channelLinks {
            channel
            externalUrl
          }
          channels {
            id
            name
            externalUrl
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
